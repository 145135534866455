<template>
	<div>
		<div class="staff-list">
			<h2 class="mb-3">ตั้งค่าผู้ใช้งาน</h2>

			<div class="row">
				<div class="col-12 col-sm-6" v-for="(item, index) in resultList" :key="index">
					<seller-staff :fields="item" @updateAuthority="getStaffList()"></seller-staff>
				</div>
			</div>

			<button class="btn btn-main btn-add square mt-4" @click="openAddStaffModal()">+ เพิ่มผู้ใช้งาน</button>
		</div>

		<modal-add-staff @close="closeAddStaffModal($event)" :isDisplay="isOpenAddStaffModal"></modal-add-staff>
	</div>
</template>

<script>
import SellerStaff from '@/components/seller/shop/Staff';
import SellerService from '@/services/sellerService';
import ModalAddStaff from '@/components/seller/shop/ModalAddStaff';
import RouterPath from '@/router/path';

export default {
	components: {
		SellerStaff,
		ModalAddStaff
	},
	data() {
		return {
			resultList: [],
			isOpenAddStaffModal: false
		}
	},
	mounted() {
		this.getStaffList();
	},
	methods: {
		async getStaffList() {
			let loader = this.$modalLoader.render();
			const result = await SellerService.getStaffList();

			if (result.status == 401) {
				this.$router.push(RouterPath.SELLER_LOGIN);
			} else if (result.data) {
				this.resultList = result.data;
			}

			loader.hide();
		},
		openAddStaffModal() {
			this.isOpenAddStaffModal = true;
		},
		closeAddStaffModal(data) {
			this.isOpenAddStaffModal = false;

			if (data.isReload) {
				this.getStaffList();
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.staff-list {
	h2 {
		font-size: 22px;
		font-family: 'Open Sans', 'Kanit';
		font-weight: bold;
	}

	.btn-add {
		width: 150px;
		height: 44px;
	}
}
</style>