<template>
    <transition name="modal" v-if="isActive">
        <perfect-scrollbar class="modal-mask">
            <div class="modal-form modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4>
                            <b-icon icon="pencil-square"></b-icon>
                            <span>เพิ่มผู้ใช้งานร้านค้า</span>
                        </h4>

                        <button class="btn-close" @click="hide(false)"><b-icon icon="x"></b-icon></button>
                    </div>
                    <div class="modal-body">
                        <div class="form">
                            <gadget-policy-input class="mb-3" :showPassNote="true"></gadget-policy-input>

                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="input-field">
                                        <label>เบอร์โทรสำหรับเข้าสู่ระบบ</label>
                                        <input type="tel"
                                            v-model="form.mobile"
                                            maxlength="12"
                                            placeholder="เบอร์โทรสำหรับเข้าสู่ระบบ"
                                            class="square lg"
                                            :class="{ 'invalid': error.mobile }" />
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="input-field">
                                        <label>ตั้งรหัสผ่าน</label>
                                        <div class="input-password">
                                            <input :type="isShowPassword ? 'text' : 'password'"
                                                maxlength="50"
                                                v-model="form.passcode"
                                                placeholder="รหัสผ่าน"
                                                class="square lg" 
                                                :class="{ 'invalid': error.passcode }" />
                                            <button class="show-password" @click="showConfirmPassword()"><b-icon :icon="isShowPassword ? 'eye-slash-fill' : 'eye-fill'"></b-icon></button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="input-field" v-if="isShowOptionManageBranch">
                                <checkbox
                                    class="chk-branch"
                                    v-model="form.isAllowBranchManage">
                                    เปลี่ยนสาขาได้
                                </checkbox>
                            </div>

                            <div class="text-center mt-3">
                                <button class="btn btn-main btn-submit" @click="saveStaff()">บันทึก</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </transition>
</template>

<script>
import Helper from '@/services/helper';
import SellerService from '@/services/sellerService';
import GadgetPolicyInput from '@/components/gadget/PolicyInput';

export default {
    components: { GadgetPolicyInput },
    data() {
		return {
            isActive: false,
            form: this.initForm(),
            error: this.initError(),
            isShowPassword: false,
            isShowOptionManageBranch: false
		};
	},
	props: {
		isDisplay: Boolean,
	},
    watch: {
        async isDisplay(value) {
            this.isActive = value;
            this.$root.$emit('rootSetContentScrollable', value);
        }
    },
    mounted() {
        this.showOptionManageBranch();
    },
    destroyed() {
        this.$root.$emit('rootSetContentScrollable', false);
    },
	methods: {
        hide(isReload) {
            this.$emit('close', { isReload: isReload });
        },
        initForm() {
            return {
                mobile: '',
                passcode: '',
                isAllowBranchManage: false
            };
        },
        initError() {
            return {
                mobile: false,
                passcode: false
            };
        },
        showConfirmPassword() {
            this.isShowPassword = !this.isShowPassword;
        },
        showOptionManageBranch() {
			if (localStorage.shopInfo) {
				const sellerInfo = JSON.parse(localStorage.shopInfo);

                if (sellerInfo.isShowBranch) {
                    this.isShowOptionManageBranch = true;
                }
			}
		},
        async saveStaff() {
            if (this.validate()) {
                let loader = this.$modalLoader.render();
                const result = await SellerService.addStaff(this.form);
                loader.hide();

                if (result.data?.success) {
                    this.form = this.initForm();

					this.hide(true);
				} else {
					this.$swal(Helper.warningAlert('ไม่สามรถเพิ่มผู้ใช้ได้', result.data?.message));
				}
            }
        },
        validate() {
            this.error = this.initError();
            let isValid = true;
            let isBlank = false;
            let isInvalidTel = false;
            let isInvalidPassword = false;

            // Check Blank
            if (!this.form.mobile.trim()) {
                this.error.mobile = true;
                isValid = false;
                isBlank = true;
            }

            if (!this.form.passcode.trim()) {
                this.error.passcode = true;
                isValid = false;
                isBlank = true;
            }

            // Check Lenght Tel
            if (this.form.mobile && this.form.mobile.length < 9) {
                this.error.mobile = true;
                isValid = false;
                isInvalidTel = true;
            }

            // Check Password Format
            const passwordPolicy = Helper.validatePasswordFormat(this.form.passcode);
            
            if (!passwordPolicy.isValid) {
                this.error.passcode = true;
                isValid = false;
                isInvalidPassword = true;
            }

            // Popup
            const title = 'ไม่สามรถเพิ่มผู้ใช้ได้';

            if (isBlank) {
                this.$swal(Helper.warningAlert(title, 'โปรดกรอกข้อมูลให้ครบถ้วน'));
            } else if (isInvalidTel) {
                this.$swal(Helper.warningAlert(title, 'รูปแบบเบอร์โทรสำหรับเข้าสู่ระบบไม่ถูกต้อง'));
            } else if (isInvalidPassword) {
                this.$swal(Helper.warningAlert('รหัสผ่านไม่ตรงตามเงื่อนไขด้านล่าง', '', Helper.getWarningPolicyMessage(passwordPolicy.message)));
            }

            return isValid;
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.modal-form {
    max-width: 600px;

    .chk-branch {
        font-size: 14px;
    }
}
</style>