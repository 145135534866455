<template>
    <transition name="modal" v-if="isActive">
        <perfect-scrollbar class="modal-mask">
            <div class="modal-form modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4>
                            <b-icon icon="pencil-square"></b-icon>
                            <span>กำหนดสิทธิ์ ({{ fields.name }})</span>
                        </h4>

                        <button class="btn-close" @click="hide()"><b-icon icon="x"></b-icon></button>
                    </div>
                    <div class="modal-body">
                        <div class="form" v-if="form">
                            <div class="item" v-for="(item, index) in fields.authority" :key="index">
                                <checkbox
                                    v-model="form[item.key]"
                                    class="me-3">
                                    {{ item.name }}
                                </checkbox>
                            </div>

                            <div class="mt-4 text-center">
                                <button class="btn btn-main btn-submit" @click="saveAuthority()">บันทึก</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </transition>
</template>

<script>
import Helper from '@/services/helper';
import SellerService from '@/services/sellerService';

export default {
    data() {
		return {
            isActive: false,
            form: null
		};
	},
	props: {
		isDisplay: Boolean,
        fields: Object
	},
    watch: {
        async isDisplay(value) {
            this.isActive = value;
            this.$root.$emit('rootSetContentScrollable', value);

            if (value) {
                this.initAuthorityList();
            }
        }
    },
    destroyed() {
        this.$root.$emit('rootSetContentScrollable', false);
    },
	methods: {
        hide(isReload) {
            this.$emit('close', { isReload: isReload });
        },
        initAuthorityList() {
            const field = {};

            this.fields.authority.forEach((item) => {
                field[item.key] = Boolean(item.isGranted);
            });

            this.form = field;
        },
        async saveAuthority() {
            const param = {
                permission: this.form,
                staffId: this.fields.id
            };
            let loader = this.$modalLoader.render();
            const result = await SellerService.changeStaffPermission(param);
            loader.hide();

            if (result.data?.success) {
                this.hide(true);
            } else {
                this.$swal(Helper.warningAlert('ไม่สามารถกำหนดสิทธิ์ได้', result.data?.message));
            }
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.modal-form {
    max-width: 400px;

    .item {
        margin-top: 5px;

        &:first-child {
            margin-top: 0;
        }
    }
}
</style>