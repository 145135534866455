<template>
	<div class="staff" v-if="form && error">
		<div class="number"><b-icon icon="person" class="icon"></b-icon> ทีมงาน #{{ fields.no }}</div>

		<div class="wrapper">
			<div class="field">
				<div class="input-field right-icon">
					<input type="tel"
						v-model="form.mobile"
						maxlength="12"
						placeholder="เบอร์โทรสำหรับเข้าสู่ระบบ"
						class="square"
						:class="{ 'invalid': error.mobile }" />
					<b-icon icon="telephone-fill" class="icon"></b-icon>
				</div>
			</div>

			<div class="field">
				<div class="input-field">
					<div class="input-password">
						<input :type="isShowPassword ? 'text' : 'password'"
							maxlength="50"
							v-model="form.passcode"
							placeholder="รหัสผ่าน"
							class="square" 
							:class="{ 'invalid': error.passcode }" />
						<button class="show-password" @click="showConfirmPassword()"><b-icon :icon="isShowPassword ? 'eye-slash-fill' : 'eye-fill'"></b-icon></button>
					</div>
				</div>
			</div>

			<div class="field">
				<div class="inline">
					<div class="input-field">
						<dropdown
							v-model="form.status"
							:options="optionStatus"
							:noTyping="true"
							class="status"
							:classes="{ input: ['square', form.status, (error.status ? 'invalid' : '')] }">
						</dropdown>
					</div>
					<div class="input-field" v-if="isShowOptionManageBranch">
						<checkbox
							class="chk-branch"
							v-model="form.isAllowBranchManage">
							เปลี่ยนสาขาได้
						</checkbox>
					</div>
				</div>
			</div>

			<div class="operation">
				<button class="btn btn-warning" @click="submitEditStaff()">แก้ไข</button>
				<button class="btn btn-outline-danger" @click="openAuthorityModal()">กำหนดสิทธิ์</button>
			</div>
		</div>

		<modal-authority @close="closeAuthorityModal($event)" :fields="fields" :isDisplay="isOpenAuthorityModal"></modal-authority>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import SellerService from '@/services/sellerService';
import OptionActiveStatus from '@/services/staticOption/activeStatus';
import ModalAuthority from '@/components/seller/shop/ModalAuthority';

export default {
	components: {
		ModalAuthority
	},
	data() {
		return {
			form: null,
			error: null,
			optionStatus: OptionActiveStatus.option,
			isOpenAuthorityModal: false,
			isShowPassword: false,
			isShowOptionManageBranch: false
		}
	},
	props: {
		fields: Object
	},
	mounted() {
		this.form = this.initForm();
		this.error = this.initError();

		this.showOptionManageBranch();
	},
	methods: {
		initForm() {
			return {
				id: this.fields.id,
				mobile: this.fields.mobile,
				passcode: this.fields.passcode,
				isAllowBranchManage: Boolean(this.fields.isAllowBranchManage),
				status: this.fields.status
			};
		},
		initError() {
			return {
				mobile: false,
				passcode: false,
				status: false
			}
		},
		showConfirmPassword() {
			this.isShowPassword = !this.isShowPassword;
		},
		showOptionManageBranch() {
			if (localStorage.shopInfo) {
				const sellerInfo = JSON.parse(localStorage.shopInfo);

                if (sellerInfo.isShowBranch) {
                    this.isShowOptionManageBranch = true;
                }
			}
		},
		async submitEditStaff() {
            if (this.validate()) {
                let loader = this.$modalLoader.render();
                const result = await SellerService.editStaff(this.form);
				loader.hide();

				if (result.data?.success) {
					this.$swal(Helper.completeAlert('บันทึกการแก้ไขเรียบร้อยแล้ว', ''));
				} else {
					this.$swal(Helper.warningAlert('ยังไม่สามารถบันทึกข้อมูลได้', result.data?.message));
				}
			}
        },
        validate() {
            this.error = this.initError();
            let isValid = true;
            let isBlank = false;
            let isInvalidTel = false;
            let isInvalidPassword = false;

            // Check Blank
            if (!this.form.mobile.trim()) {
                this.error.mobile = true;
                isValid = false;
                isBlank = true;
            }

            if (!this.form.passcode.trim()) {
                this.error.passcode = true;
                isValid = false;
                isBlank = true;
            }

            // Check Lenght Tel
            if (this.form.mobile && this.form.mobile.length < 9) {
                this.error.mobile = true;
                isValid = false;
                isInvalidTel = true;
            }

            // Check Password Format
			const passwordPolicy = Helper.validatePasswordFormat(this.form.passcode);

            if (!passwordPolicy.isValid) {
                this.error.passcode = true;
                isValid = false;
                isInvalidPassword = true;
            }

            // Popup
            const title = 'ไม่สามารถแก้ไขข้อมูลได้';

            if (isBlank) {
                this.$swal(Helper.warningAlert(title, 'โปรดกรอกข้อมูลให้ครบถ้วน'));
            } else if (isInvalidTel) {
                this.$swal(Helper.warningAlert(title, 'รูปแบบเบอร์โทรสำหรับเข้าสู่ระบบไม่ถูกต้อง'));
            } else if (isInvalidPassword) {
                this.$swal(Helper.warningAlert('รหัสผ่านไม่ตรงตามเงื่อนไขด้านล่าง', '', Helper.getWarningPolicyMessage(passwordPolicy.message)));
            }

            return isValid;
        },
		openAuthorityModal() {
			this.isOpenAuthorityModal = true;
		},
		closeAuthorityModal(data) {
			this.isOpenAuthorityModal = false;

			if (data.isReload) {
				this.$emit('updateAuthority');
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.staff {
	position: relative;
	border: 1px dashed #ccc;
	margin-top: 15px;
	padding: 0 0 20px;

	.number {
		font-size: 12px;
		background: #f2f2f2;
		color: #333;
		padding: 10px 15px;
		margin-bottom: 20px;

		.icon {
			margin-right: 5px;
		}
	}

	.wrapper {
		padding: 0 20px 0;
	}

	.field {
		margin-bottom: 10px;

		.inline {
			display: flex;
			align-items: center;

			.input-field:first-child {
				margin-right: 15px;
			}
		}
		
		.input-field {
			position: relative;

			&.right-icon {
				display: flex;
				align-items: center;

				.icon {
					position: absolute;
					right: 12px;
					opacity: 0.2;
				}
			}

			.input-password {
				width: 100%;

				.show-password {
					position: absolute;
					right: 10px;
					padding: 0;
					font-size: 22px;
				}
			}

			input {
				padding: 9px 15px;
				border: 1px solid #aaa;
				font-size: 14px;
			}

			.chk-branch {
				font-size: 14px;
			}
		}

		.status {
			width: 140px;
		}
	}

	.operation {
		button {
			width: 45%;
			height: 38px;
			font-size: 14px;
			
			@media only screen and (max-width: $screenMedium) {
				font-size: 14px;
			}
		}
	}
}

::v-deep .form-option {
    input {
		border: 1px solid #aaa;
		font-size: 14px;

		&[readonly] {
            background: #fff;
        }

		&.active {
			color: #1cad99;
		}

		&.inactive {
			color: #cc0000;
		}
    }
}
</style>